exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certificates-en-js": () => import("./../../../src/pages/certificates.en.js" /* webpackChunkName: "component---src-pages-certificates-en-js" */),
  "component---src-pages-datenschutz-de-js": () => import("./../../../src/pages/datenschutz.de.js" /* webpackChunkName: "component---src-pages-datenschutz-de-js" */),
  "component---src-pages-haftungsausschluss-de-js": () => import("./../../../src/pages/haftungsausschluss.de.js" /* webpackChunkName: "component---src-pages-haftungsausschluss-de-js" */),
  "component---src-pages-impressum-de-js": () => import("./../../../src/pages/impressum.de.js" /* webpackChunkName: "component---src-pages-impressum-de-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-story-door-control-module-en-js": () => import("./../../../src/pages/story/door-control-module.en.js" /* webpackChunkName: "component---src-pages-story-door-control-module-en-js" */),
  "component---src-pages-story-ev-charger-en-js": () => import("./../../../src/pages/story/ev-charger.en.js" /* webpackChunkName: "component---src-pages-story-ev-charger-en-js" */),
  "component---src-pages-story-multifunktions-touchlenkrad-de-js": () => import("./../../../src/pages/story/multifunktions-touchlenkrad.de.js" /* webpackChunkName: "component---src-pages-story-multifunktions-touchlenkrad-de-js" */),
  "component---src-pages-story-prueffinger-de-js": () => import("./../../../src/pages/story/prueffinger.de.js" /* webpackChunkName: "component---src-pages-story-prueffinger-de-js" */),
  "component---src-pages-story-test-finger-en-js": () => import("./../../../src/pages/story/test-finger.en.js" /* webpackChunkName: "component---src-pages-story-test-finger-en-js" */),
  "component---src-pages-story-touch-steering-wheel-en-js": () => import("./../../../src/pages/story/touch-steering-wheel.en.js" /* webpackChunkName: "component---src-pages-story-touch-steering-wheel-en-js" */),
  "component---src-pages-story-tuerbedienmodul-de-js": () => import("./../../../src/pages/story/tuerbedienmodul.de.js" /* webpackChunkName: "component---src-pages-story-tuerbedienmodul-de-js" */),
  "component---src-pages-story-wallbox-de-js": () => import("./../../../src/pages/story/wallbox.de.js" /* webpackChunkName: "component---src-pages-story-wallbox-de-js" */),
  "component---src-pages-zertifikate-de-js": () => import("./../../../src/pages/zertifikate.de.js" /* webpackChunkName: "component---src-pages-zertifikate-de-js" */),
  "component---src-templates-aktuelles-de-js": () => import("./../../../src/templates/aktuelles.de.js" /* webpackChunkName: "component---src-templates-aktuelles-de-js" */),
  "component---src-templates-article-en-js": () => import("./../../../src/templates/article.en.js" /* webpackChunkName: "component---src-templates-article-en-js" */),
  "component---src-templates-artikel-de-js": () => import("./../../../src/templates/artikel.de.js" /* webpackChunkName: "component---src-templates-artikel-de-js" */),
  "component---src-templates-news-en-js": () => import("./../../../src/templates/news.en.js" /* webpackChunkName: "component---src-templates-news-en-js" */)
}

